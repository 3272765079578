.careerbackground{
    background-image: url(../../../public/Assets/career1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.menu_wrapper{
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 6px #ccc;
}
.menu_wrapper .nav-item {
    margin: 10px 0px;
}
.menu_wrapper .nav-item a.active{
    color: #fff;
    background-color: #08667e !important;
    font-size: 18px;
}
.department_content {
    padding: 20px;
    box-shadow: 0 0 6px #ccc;
    margin-bottom: 50px;
    border-radius: 15px;
}
.department_content h3 {
    text-align: center;
    font-size: 30px;
    text-shadow: 0 0 6px #b7b7b7;
    border-bottom: 1px solid #ccc;
}
.department_content h4 {
    margin: 20px 0;
    font-style: oblique;
}
.department_content li{
    color: rgb(116, 116, 116);
}
.department_content{
    background-image: url(../../../public/Assets/career2.png);
    background-repeat: no-repeat;
    background-size: cover;

}