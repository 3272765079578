.getPartner-wrapper{
    background: #f970790c;
    padding: 50px;
}
.card-wrapper {
    background: #fff;
    padding: 30px;
    box-shadow: 0 0 6px #ccc;
    height: 344px;
}
.getPartner-wrapper .owl-carousel .owl-nav button.owl-next {
    width: 90px;
    height: 90px;
    background-image: url(../../../../public/Assets/icon/icon-left-right.png);    
    background-repeat: no-repeat;
    transform: rotate(180deg);
}
.getPartner-wrapper .owl-carousel .owl-nav button.owl-prev {
    width: 90px;
    height: 90px;
    background-image: url(../../../../public/Assets/icon/icon-left-right.png);
    background-repeat: no-repeat;
}
.getPartner-wrapper .owl-theme .owl-nav [class*='owl-']:hover {
    background-image: url(../../../../public/Assets/icon/icon-left-right.png) !important;
    background-repeat: no-repeat;
    color: #FFF;
    text-decoration: none;
}
.getPartner-wrapper .owl-theme .owl-nav [class*='owl-']:hover {
    background: #fff8f9;
    color: #FFF;
    text-decoration: none;
}
.getPartner-wrapper{
    position: relative;
}
.getPartner-wrapper .owl-nav {
    position: absolute;
    right: 0;
    top: -130px;
}
.icon-bar img {
    width: 100px !important;
    margin-bottom: 30px;
}
.link-box {
    color: #fff;
    background: linear-gradient(207deg,#f9707a, #a605f7);
    width: 161px;
    padding: 11px 20px;
    margin: 10px 40px;
    border-radius: 8px;
    margin-top: -20px;
    box-shadow: 0 0 6px #ccc;
}
.link-box a {
    list-style: none;
    text-decoration: none;
    color: #fff;
}
.link-box span i {
    margin-left: 11px;
    font-size: 17px;
    font-weight: bolder;
}