.swal-overlay {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .swal-button {
    padding: 7px 39px;
    border-radius: 2px;
    background-image: linear-gradient(207deg,#f9707a, #a605f7);
    font-size: 12px;
    border: 1px solid #3e549a;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }