.about-us-banner{
    padding: 0px !important;
    margin: 0px;
    position: relative;
}
.about-us-banner img{
width: 100%;
}

.content-wrapper {
    width: 30%;
        position: absolute;
        z-index: 99;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 100px;
        padding: 20px;
}
.content-wrapper h2{
    text-align: center;
    color: #fff;
    font-family: poppins;
    font-weight: 700;
    border-bottom: 2px dashed rgb(88, 88, 88);
    font-size: 60px !important;
}
.about-us_first h2{
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 100px;
    color: #001250;
    text-align: center;
}