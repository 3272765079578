/* Top Navigation */
*{
    padding: 0;
    margin: 0;
}
.infoWrapper{
    background: linear-gradient(207deg, #f9707a,#a605f7);
}
.infoWrapper ul {
    margin: 0;
}
.infoWrapper ul li{
    list-style: none;
    display: inline;
    float: right;
    padding: 10px;
    margin: 0;
    padding-left: 40px;
}
.infoWrapper ul li {
    background-image: url(../../../public/Assets/sprit.png);
}
.infoWrapper ul li a{
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.infoWrapper ul li {
    background-position: -57px;
    background-repeat: no-repeat;
    background-size: 400px;
}
.infoWrapper ul li:nth-child(1){
    padding-left: 10px !important;
}
.infoWrapper ul li:nth-child(3) {
    background-position: -2px;
}
.infoWrapper ul li:nth-child(4) {
    background-position: -168px;
}

/* End of Top Navigation */

/* Header Section */
.App-logo{
    width: 90px ;
}
.HeaderBar .nav-link{
    color: #0a163f;
    font-size: 17px;
}
a.linkWrapper {
    width: 50%;
    display: inline-block;
}
.dropdown-menu {
    min-width: 30rem;
}
.dropdown-menu.show {
    padding: 20px !important;
}
.HeaderBar .navbar-collapse .nav-item .dropdown-menu a {
    text-decoration: none;
    font-size: 17px;
    padding: 3px;
    color: #2e2e2e;
}
.navbar{
    z-index: 9;
}
.HeaderBar{
}
.linkWrapper_anc{
    text-decoration: none !important;
}
/* Header Section End*/

/* Banner Section */
.App-banner{
    padding:0px;
    width: 100%;
    margin: -107px 0px 0px 0px;
}
h1 {
    font-size:50px;
    text-align:center;
    padding: 10% 0;
  }
  .download-btn{
    background-image: linear-gradient(207deg,#f9707a, #a605f7);
    border: none;
    color: #fff;    
    top: -290px;
    font-size: 17px;
    left: 120px;
    padding: 10px;
    border-radius: 3px;
  }
  
  #changingword {
    color: #162249;
    /* position: absolute; */
    /* top: -650px; */
    font-weight: 700;
    /* left: 120px; */
    width: 500px;
    text-align: left;
    font-size: 50px;
}
#changingpara {
    color: #162249;
    /* position: absolute;
    top: -500px; */
    font-size: 17px;
    left: 120px;
    width: 450px;
}
.wrapperWord {
    position: absolute;
    left: 140px;
    top: 130px;
    width: 560px;
}
.Btnwrapper {
    background-image: linear-gradient(207deg,#f9707a, #a605f7);
    border: none;
    color: #fff;
    font-weight: 600;
    /* position: absolute; */
    /* top: -290px; */
    font-size: 17px;
    /* left: 120px; */
    width: 200px;
    padding: 10px;
    border-radius: 35px;
}
/* End of Banner Section */
/* Banner Second */
    .bannerSecond_bx{
        padding: 10px;
        margin-top: 60px;
        margin-bottom: 40px;
    }
    .content_bar{
        border: 2px solid rgb(219, 3, 172);
        width: 170px;
        display: inherit;
        margin: auto;
        border-radius: 50%;
        height: 1px !important;
        margin-bottom: 10px;
    }
/* End of Banner Second */

/* Client Section */
.clientCrousel .owl-item {
    padding: 40px;
}
/* End Client Section */

/* mobile mockup  */
.mobile_wrapper{
    background-image: linear-gradient(207deg,#f9707a, #a605f7);
}
.mobile_wrapper img{
    width: 50%;
}
.mobile_wrapper h2{
    font-size: 40px;
    font-weight: 600;
    color: #fff;
}
.mobile_wrapper p{
    color: #fff;
    font-size: 17px;
}
.wrapper_bx{
    box-shadow: rgb(240 101 134) 0px 0px 6px;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
    text-align: center;
    min-height: 364px !important;
}
.wrapper_bx h3{
    font-size: 22px;
}
.wrapper_bx p{
    font-size: 15px;
    color: rgb(161, 159, 159);
}
/*  End mobile mockup  */

/* Get In Touch */
.getinwrapper{
    padding: 30px;
    box-shadow: 0 0 6px #ccc;
    border: 1px solid #ccc;
}
.getInContent h2{
    font-weight: 800;
    font-family: 'Roboto', sans-serif;
}
.formWrapper{
    width: 500px;
    padding: 35px 35px ;
    display: inline-block;
    background: #fff;
    box-shadow: 0 0 6px #ccc;
}
.formWrapper input{
    width: 100%;
    padding: 10px 10px;
    margin: 10px 0px 10px 0px ;
    border: none;
    background-color: rgb(248, 248, 248);
    border-radius: 5px;
}
.formWrapper textarea{
    width: 100%;
    padding: 10px 10px;
    margin: 10px 0px 10px 0px ;
    border: none;
    background-color: rgb(248, 248, 248);
    border-radius: 5px;
}
.formWrapper {
    width: 500px;
    padding: 55px 35px;
    display: inline-block;
    background: #fff;
    box-shadow: 0 0 6px rgb(99, 94, 94);
    position: absolute;
    top: -120px;
    right: 140px;
}
.formSubmit {
    margin-top: 30px;
    background-image: linear-gradient(207deg,#f9707a, #a605f7);
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    width: 100%;
    padding: 10px;
    border-radius: 35px;
}
.getIntouch_list{
    margin: auto;
    padding: 45px;
    color:#fff;
}
.getintouch_list_bx{
    background: linear-gradient(207deg, #f9707a,#a605f7);
    position: relative;
}
.getIntouch_list p{
    font-size: 20px;
    font-weight: 600;
    font-family: 'poppins', sans-serif;
}
.getIntouch_list ul li{
    font-size: 20px;
    font-family: 'poppins', sans-serif;
}
/* Get In Touch */

/* Footer */
.footerWrapper {
    /* background: #ffd2df; */
    background: #09153c;
    background-image: url(/public/Assets/bg_footer.png);
    padding: 70px;
    color: #fff;
}
.footerWrapper ul li a{
    text-decoration: none;
    color: #fff;
}
.footerWrapper ul{
    padding: 0px; 
}
.footerWrapper ul li{
    list-style: none;
}
.footerWrapper .row .col ul li a{
    color: #ccc;
    font-size: 16px;
}
.footerWrapper .row .col p{
    padding-top:15px;
    font-size:16px;
    color:#ccc;
    padding-right: 50px;
}
.footerWrapper .row .col  h3{
    font-size: 18px;
}
.footerWrapper .row .col ul li{
    color: #ccc;
    font-size: 16px;
}
/* End Footer */

/* RESPONSIVE HOME */

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
.wrapperWord {
    left: 0px;
    top: 10px;
    right: 0px;    
    width: 100%;
    text-align: center;
}
.infoWrapper ul {
    text-align: center;
    margin: 0;
    padding: 0px;
    margin: auto;
}
.infoWrapper ul li{
    text-align: center;
    margin: auto;
}
.navbar{
    z-index: inherit;
}
.App-logo {
    width: 100px;
}
.App-banner {
    margin-top: -10px;
}
#changingword {
    font-size: 20px;
}
#changingpara {
    background-color: rgb(255 255 255 / 86%);
    color: #162249;
    font-size: 17px;
    left: 120px;
    display: inline;
    width: 450px;
    font-size: 9px;
    display: inherit;
    margin: auto;
    /* line-height: 1; */
    padding: 0px;
    width: 230px;
}
.Btnwrapper {
    background-image: linear-gradient(207deg,#f9707a, #a605f7);
    border: none;
    color: #fff;
    /* font-weight: 600; */
    /* position: absolute; */
    /* top: -290px; */
    font-size: 13px;
    /* left: 120px; */
    width: 120px;
    padding: -19px;
    padding: 4px;
    border-radius: 35px;
}
.col {
    flex: inherit;
    width: 100%;
    text-align: center;
}
.bnnerSecond_bx.row {
    margin-top: 40px;
}
.order2{
    order: 1 !important;
}
.order1{
    order: 2 !important;
}
.mobile_wrapper h2 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}
.getIntouch_list{
    order: 2;
}
.formWrapper {
    width: 320px;
    padding: 15px 35px;
    display: inline-block;
    background: #fff;
    box-shadow: 0 0 6px rgb(99, 94, 94);
    position: relative;
    top: 0px;
    left: 0px;
}
.getintouch_list_bx {
    margin: 0;
    padding: 0px;
}
.getIntouch_list p {
    font-size: 10px;
}
.getIntouch_list ul li {
    font-size: 12px;
    font-family: 'poppins', sans-serif;
}
.footerWrapper {
 padding: 5px;
}
.getIntouch_list.col ul li {
    text-align: left;
}
.dropdown-menu {
    min-width: 17rem;
}
a.linkWrapper {
    width: 100%;
    display: inline-block;
}
}
/* RESPONSIVE HOME */
